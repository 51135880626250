import React from "react";
import { Button } from '@ctt/ui';

export default function Root(props) {
  return (
    <div className="header_main">
      {props.name} is mounted!!!
      <Button>TESTE</Button>
    </div>
  )
}
