// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_main {
    height: 70px;
    background-color: var(--color-purple);
}`, "",{"version":3,"sources":["webpack://./src/root.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qCAAqC;AACzC","sourcesContent":[".header_main {\n    height: 70px;\n    background-color: var(--color-purple);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
